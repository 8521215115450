<template>
  <div :class="$style.gallery">
    <div v-if="(existingImages.length + videos.length) && !hasImageError" :class="$style.galleryContainer">
      <div :class="$style.galleryImageContainer">
        <div v-if="numberOfMedia" :class="$style.counter">
          <span aria-hidden="true" v-text="numberOfMedia" />
          <span class="awSrOnlyAbs" v-text="`${numberOfMedia} ${$awt('aw.common.product.gallery.num_of_media')}`" />
        </div>
        <div :class="$style.zoomButtonContainer">
          <lv-button styling="small-secondary-dark-just-icon" :disabled="isVideoSelected" @click="zoomOut">
            <lv-icon :size="16" name="zoom-out-16" style="vertical-align: unset" />
          </lv-button>
          <lv-button styling="small-secondary-dark-just-icon" :disabled="isVideoSelected" @click="zoomIn">
            <lv-icon :size="16" name="zoom-in-16" style="vertical-align: unset" />
          </lv-button>
        </div>
        <div :class="[$style.navButton, $style.navButtonPrev]">
          <lv-button styling="small-primary-dark-just-icon" :disabled="slide <= 0" @click="$refs.imageCarousel.prev()">
            <lv-icon :size="16" name="arrow-left-24" style="vertical-align: unset" />
          </lv-button>
        </div>

        <aw-b-carousel
          ref="imageCarousel"
          v-model="slide"
          :interval="0"
          :class="$style.galleryCarousel"
          style="position: relative"
        >
          <b-carousel-slide
            v-for="(currentMedia, index) in fullMediaList.list"
            :key="`carousel-slide-${index}`"
            background="transparent"
            :class="$style.galleryCarouselSlide"
          >
            <template #img>
              <div :class="$style.img">
                <div
                  v-if="currentMedia.type === 'variant'"
                  :class="[$style.imageZoomer, {[$style.imageZoomerZoomed]: zoomLevel > 0}]"
                  @click="openModal"
                >
                  <aw-image-zoomer
                    :key="`video-thumbnail-1-${currentMedia.thumbnailKey}-${index}`"
                    :url="currentMedia.url"
                    :webp-url="isWebpSupportedInBrowser ? `${currentMedia.url}.webp` : null"
                    :zoom="1 + (zoomLevel * ZOOM_MODIFIER)"
                    @image-error="hasImageError = true"
                  />
                </div>
                <div v-else-if="!playVideo" :class="[$style.galleryImageVideo, $style.galleryImageVideoImage]">
                  <lv-button
                    shrink
                    styling="primary-light-just-icon"
                    :stretch="true"
                    :class="[$style.videoItemCentered]"
                    @click="playVideo = true"
                  >
                    <lv-icon name="play-version2-16" :size="24" :class="[$style.videoItemIcon]" />
                  </lv-button>
                  <lv-image
                    :key="`video-thumbnail-1-${currentMedia.thumbnailKey}`"
                    type="youtube"
                    :sources="currentMedia.youtubeThumbnailSources"
                  />
                </div>
                <div v-else :class="$style.galleryImageVideo">
                  <aw-vimeo-video :id="currentMedia.url" :class="$style.vimeoVideo" />
                </div>
              </div>
            </template>
          </b-carousel-slide>
        </aw-b-carousel>

        <div :class="[$style.navButton, $style.navButtonNext]">
          <lv-button
            styling="small-primary-dark-just-icon"
            :disabled="!(slide < fullMediaList.fullLength - 1)"
            @click="$refs.imageCarousel.next()"
          >
            <lv-icon :size="16" name="arrow-right-24" style="vertical-align: unset" />
          </lv-button>
        </div>
      </div>

      <div
        v-if="fullMediaList.list.length > 1"
        aria-hidden="true"
        :class="[$style.thumbnailContainer, {[$style.thumbnailContainerCenter]: fullMediaList.list.length < 4}]"
      >
        <!-- aria-hidden is applied due synchronised carousels being mainly
        design elements. Duplicated content is likely irrelevant for
        screen reader users -->
        <div
          v-for="(currentMedia, i) in fullMediaList.list"
          ref="thumbnails"
          :key="i"
          :class="[$style.thumbnailItem, {[$style.thumbnailItemSelected]: slide === i, [$style.thumbnailItemVideo]: currentMedia.type !== 'variant'}]"
          :style="i === 0 ? `margin-left: ${thumbnailTrackOffset}px` : ''"
          @click="goToValue(i)"
        >
          <lv-image
            v-if="currentMedia.type === 'variant'"
            :class="$style.thumbnailItemImage"
            :sources="webpFallback([{url: `${currentMedia.url}`}])"
            sizing="contain"
            :alt="productName"
          />
          <lv-image
            v-else
            :key="`video-thumbnail-2-${currentMedia.thumbnailKey}`"
            type="youtube"
            :class="$style.thumbnailItemImage"
            :sources="currentMedia.youtubeThumbnailSources"
          />
          <div v-if="currentMedia.type !== 'variant'" :class="$style.thumbnailItemVideoButton">
            <lv-icon
              name="play-version2-16"
              :size="16"
              :class="[$style.videoItemIcon]"
              style="vertical-align: unset"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else :class="$style.emptyGallery">
      <div>
        <img
          :src="$addWebpPostfixIfSupported({ url: abstract77, webpUrl: abstract77Webp })"
          loading="lazy"
          :class="$style.emptyGalleryImage"
          alt=""
        >
      </div>
      <div :class="$style.emptyGalleryText">
        {{ $awt('aw.common.product.gallery.no_image') }}
      </div>
    </div>

    <aw-modal
      v-if="needModal"
      :opened="modal.type === modalType"
      size="sm"
      @close-modal="closeModal"
    >
      <template #content>
        <div :class="$style.modalContent">
          <div :class="$style.zoomButtonContainer">
            <lv-button styling="small-secondary-dark-just-icon" @click="modalZoomOut">
              <lv-icon :size="16" name="zoom-out-16" />
            </lv-button>
            <lv-button styling="small-secondary-dark-just-icon" @click="modalZoomIn">
              <lv-icon :size="16" name="zoom-in-16" />
            </lv-button>
          </div>
          <div :class="$style.modalGallery">
            <div :class="[$style.modalNavButton, $style.modalNavButtonPrev]">
              <lv-button
                styling="small-primary-dark-just-icon"
                :disabled="slide <= 0"
                @click="$refs.imageCarousel.prev()"
              >
                <lv-icon :size="16" name="arrow-left-24" style="vertical-align: unset" />
              </lv-button>
            </div>
            <div :class="[$style.img, $style.imageZoomer]">
              <aw-b-carousel
                ref="imageCarousel"
                v-model="slide"
                :interval="0"
                :class="$style.galleryCarousel"
                style="position: relative"
              >
                <b-carousel-slide
                  v-for="(currentMedia, index) in fullMediaList.list"
                  :key="`carousel-slide-${index}`"
                  background="transparent"
                  :class="$style.galleryCarouselSlide"
                >
                  <template #img>
                    <div :class="[$style.img, $style.imgModal]">
                      <div
                        v-if="currentMedia.type === 'variant'"
                        :class="[$style.imageZoomer, {[$style.imageZoomerZoomed]: modalZoomLevel > 0}]"
                        @click="openModal"
                      >
                        <aw-image-zoomer
                          :key="`video-thumbnail-1-${currentMedia.thumbnailKey}-${index}`"
                          :url="currentMedia.url.replace('product_medium', 'product_large')"
                          :webp-url="isWebpSupportedInBrowser ? `${currentMedia.url.replace('product_medium', 'product_large')}.webp` : null"
                          :zoom="1 + (modalZoomLevel * ZOOM_MODIFIER)"
                          @image-error="hasImageError = true"
                        />
                      </div>
                      <div v-else-if="!playVideo" :class="[$style.galleryImageVideo, $style.galleryImageVideoImage]">
                        <lv-button
                          shrink
                          styling="primary-light-just-icon"
                          :stretch="true"
                          :class="[$style.videoItemCentered]"
                          @click="playVideo = true"
                        >
                          <lv-icon name="play-version2-16" :size="24" :class="[$style.videoItemIcon]" />
                        </lv-button>
                        <lv-image
                          :key="`video-thumbnail-1-${currentMedia.thumbnailKey}`"
                          type="youtube"
                          :sources="currentMedia.youtubeThumbnailSources"
                        />
                      </div>
                      <div v-else :class="$style.galleryImageVideo">
                        <aw-vimeo-video :id="currentMedia.url" :class="$style.vimeoVideo" />
                      </div>
                    </div>
                  </template>
                </b-carousel-slide>
              </aw-b-carousel>
            </div>
            <div :class="[$style.modalNavButton, $style.modalNavButtonNext]">
              <lv-button
                styling="small-primary-dark-just-icon"
                :disabled="!(slide < fullMediaList.fullLength - 1)"
                @click="$refs.imageCarousel.next()"
              >
                <lv-icon :size="16" name="arrow-right-24" style="vertical-align: unset" />
              </lv-button>
            </div>
          </div>
        </div>
      </template>
    </aw-modal>

    <div v-if="idFlixMinisite" :id="idFlixMinisite" />
  </div>
</template>

<script>
  import { mapState } from 'pinia';
  import { defineAsyncComponent, nextTick } from 'vue';
  import { LvIcon, LvImage, LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwModal from '~~/common/components/Common/AwModal';
  import { PUSH_ZOOM_PRODUCT } from '~~/common/plugins/aw-analytics.js';
  import { uuid4, webpFallback } from '~~/common/utils';
  import AwVimeoVideo from '~~/common/components/Common/AwVimeoVideo';
  import productDataMixin from '~~/common/mixins/productDataMixin';
  import pageInstanceMixin from '~~/common/mixins/pageInstanceMixin';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { useProductStore } from '~~/common/stores/product';
  import { M_PROD_GALLERY } from '~~/common/config/Modal.js';
  import abstract77 from '~~/common/assets/images/abstract-77.png';
  import abstract77Webp from '~~/common/assets/images/abstract-77.png.webp';
  import { useModalStore } from '~~/common/stores/modal.js';
  import { useMediaHelperStore } from '~~/common/stores/mediaHelper.js';
  import AwBCarousel from '~~/common/components/AwBCarousel.vue';

  const MAX_ZOOM_LEVEL = 4;
  const ZOOM_MODIFIER = 0.25;

  const TRACK_OFFSET = 12;

  export default {
    name: 'AwProductGalleryVersion2',
    components: {
      AwImageZoomer: defineAsyncComponent(() => import('~~/common/components/Common/AwImageZoomer.vue')),
      AwModal,
      LvIcon,
      LvImage,
      LvButton,
      AwVimeoVideo,
      AwBCarousel,
    },
    mixins: [
      pageInstanceMixin,
      productDataMixin,
    ],
    props: {
      idFlixMinisite: {
        type: String,
        required: false,
        default: '',
      },
      needModal: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        modalType: M_PROD_GALLERY + uuid4(),
        zoomLevel: 0,
        modalZoomLevel: 0,
        slide: 0,
        fullMediaList: {
          fullLength: 0,
          list: [],
        },
        playVideo: false,
        thumbnailWidth: 0,
        resizeObserver: new ResizeObserver(() => this.onResize()),
        ZOOM_MODIFIER,
        abstract77,
        abstract77Webp,
        hasImageError: false,
      };
    },
    computed: {
      ...mapState(useProductStore, {
        product: state => state.data,
      }),
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
      ...mapState(useModalStore, {
        modal: state => state.activeGenericModal,
      }),
      ...mapState(useMediaHelperStore, {
        isWebpSupportedInBrowser: state => state.isWebpSupportedInBrowser,
      }),
      isVideoSelected () {
        return this.fullMediaList.list[this.slide]?.type !== 'variant';
      },
      numberOfMedia () {
        return `${this.slide + 1} / ${this.existingImages.length + this.videos.length}`;
      },
      thumbnailTrackOffset () {
        if (this.fullMediaList.list.length < 4) {
          return 0;
        }
        if (this.slide <= 1) {
          return TRACK_OFFSET * 2;
        }
        const slideOffsetCount = Math.min(this.slide, this.fullMediaList.list.length - 3);
        return ((slideOffsetCount - 1) * this.thumbnailWidth) * -1 - TRACK_OFFSET * (slideOffsetCount - 3);
      },
    },
    watch: {
      slide () {
        this.playVideo = false;
        this.zoomLevel = 0;
      },
      existingImages () {
        this.setFullMediaList();
      },
      images: {
        immediate: true,
        deep: true,
        handler: async function (newVal) {
          this.existingImages = await this.filterExistingImages(newVal);
        },
      },
    },
    mounted () {
      this.setFullMediaList();
    },
    methods: {
      goToValue (index) {
        this.$refs?.imageCarousel?.goToValue(index);
      },
      webpFallback,
      openModal () {
        if (!this.needModal) {
          return;
        }

        const modalStore = useModalStore();
        modalStore.showProdGallery({ modalType: this.modalType });
      },
      closeModal () {
        const modalStore = useModalStore();
        modalStore.hideProdGallery({ modalType: this.modalType });
      },
      async setFullMediaList () {
        const list = [];
        const addImage = (currentItem) => {
          list.push({
            url: currentItem,
            type: 'variant',

          });
        };
        const addVideo = async (currentItem) => {
          const thumbnailKey = await this.videoThumbnailKey(currentItem);
          const youtubeThumbnailSources = await this.asyncGetYoutubeThumbnailSources(currentItem);
          list.push({
            url: currentItem,
            thumbnailKey,
            youtubeThumbnailSources,
          });
        };
        const images = [...this.existingImages];
        const videos = [...this.videos];
        const mainMedia = this.mainMedia;
        if (mainMedia.type === 'video') {
          await addVideo(mainMedia.url);
          for (const currentVideo of videos) {
            if (currentVideo !== mainMedia.url) {
              await addVideo(currentVideo);
            }
          }
          for (const currentImage of images) {
            addImage(currentImage);
          }
        }
        if (mainMedia.type === 'image') {
          addImage(mainMedia.url);
          for (const currentVideo of videos) {
            await addVideo(currentVideo);
          }
          for (const currentImage of images) {
            if (mainMedia.url !== currentImage) {
              addImage(currentImage);
            }
          }
        }
        this.fullMediaList = {
          fullLength: this.existingImages.length + this.videos.length,
          list,
        };

        nextTick(() => {
          if (this.$refs.thumbnails?.length) {
            this.resizeObserver.observe(this.$refs.thumbnails[0]);
            this.onResize();
          }
        });
      },
      zoomIn () {
        if (this.zoomLevel < MAX_ZOOM_LEVEL) {
          this.zoomLevel++;
          try {
            this.$awAnalytics[PUSH_ZOOM_PRODUCT]();
          } catch (err) {
            this.$logger.error(err);
          }
        }
      },
      zoomOut () {
        if (this.zoomLevel > 0) {
          this.zoomLevel--;
        }
      },
      modalZoomIn () {
        if (this.modalZoomLevel < MAX_ZOOM_LEVEL) {
          this.modalZoomLevel++;
          try {
            this.$awAnalytics[PUSH_ZOOM_PRODUCT]();
          } catch (err) {
            this.$logger.error(err);
          }
        }
      },
      modalZoomOut () {
        if (this.modalZoomLevel > 0) {
          this.modalZoomLevel--;
        }
      },
      onResize () {
        this.thumbnailWidth = this.$refs.thumbnails?.length ? (this.$refs.thumbnails?.[0]?.getBoundingClientRect?.()?.width ?? this.thumbnailWidth) : this.thumbnailWidth;
      },
      async filterExistingImages (images) {
        function checkImageExists (item) {
          return new Promise((resolve) => {
            const img = new Image();

            img.onload = () => resolve({
              url: item,
              exists: true,
            });
            img.onerror = () => resolve({
              url: item,
              exists: false,
            });

            img.src = item;
          });
        }

        const imageTestPromises = images.map(item => checkImageExists(item));
        const imageTestResults = await Promise.all(imageTestPromises);
        return imageTestResults.filter(item => item.exists).map(item => item.url);
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.item {
  margin: 0 12px;
  cursor: pointer;
  border: 2px solid transparent;

  &Active {
    border-color: $color-marigold-300;
  }
}

.emptyGallery {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px dashed $color-dash-separator;
  border-radius: 24px;
  aspect-ratio: 560/544;

  &Image {
    width: 144px;
    height: 144px;
  }

  &Text {
    font-size: 22px;
    font-weight: $font-weight-bold-v2;
    line-height: 28px;
    color: $color-text-tertiary;
  }
}

.gallery {
  width: 100%;

  &Container {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &ImageVideo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-height: 410px;
    aspect-ratio: 1/1;

    &Image::before {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      background-color: $color-olive-750--50;
    }
  }

  &ImageContainer {
    position: relative;
    padding: 64px;
    border-radius: 24px;
    background-color: $color-background-4;

    @include desktop-small(min) {
      padding: 80px;
    }
  }

  &Carousel {
    &Slide {
      border-radius: 24px;
    }
  }
}

.zoomButtonContainer {
  position: absolute;
  z-index: 1;
  top: 16px;
  right: 16px;
  display: flex;
  gap: 8px;

  @include desktop-small(min) {
    top: 24px;
    right: 24px;
  }
}

.navButton {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);

  &Prev {
    left: 16px;

    @include desktop-small(min) {
      left: 24px;
    }
  }

  &Next {
    right: 16px;

    @include desktop-small(min) {
      right: 24px;
    }
  }
}

.modalGallery {
  display: grid;
  grid-template-columns: 32px 1fr 32px;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  @include desktop-small(min) {
    gap: 24px;
  }
}

.counter {
  font-size: 14px;
  font-weight: $font-weight-normal-v2;
  line-height: 20px;
  position: absolute;
  z-index: 1;
  top: 16px;
  left: 16px;
  padding: 6px 12px;
  color: $color-text-primary;
  border-radius: 8px;
  background: $color-background-3;
  box-shadow: 0 2px 8px 0 $color-olive-750--8;

  @include desktop-small(min) {
    top: 24px;
    left: 24px;
  }
}

.img {
  overflow: hidden;
  text-align: center;
  background: transparent;

  img {
    width: 100%;
    max-width: unset;
  }

  &, :global(.vh--holder) {
    border-radius: 16px;
  }

  &Modal {
    padding-top: 40px;
  }
}

.thumbnail {
  &Container {
    display: none;
    overflow: hidden;
    width: 100%;
    gap: 12px;

    &Center {
      justify-content: center;
    }

    @include tablet(min) {
      display: flex;
    }
  }

  &Item {
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    width: calc(25% - 21px);
    min-width: min-content;
    padding: 16px;
    transition: margin-left 0.5s linear;
    border-radius: 16px;
    background-color: $color-background-4;
    box-shadow: 0 2px 8px 0 $color-olive-750--8;
    aspect-ratio: 119 / 88;

    &Selected {
      border: 2px solid $color-marigold-300;
    }

    &Video {
      &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        background-color: $color-olive-750--20;
      }
    }

    &VideoButton {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 32px;
      height: 32px;
      padding: 8px;
      transform: translateY(-50%) translateX(-50%);
      border-radius: 8px;
      background-color: $color-background-4;
    }

    &Image {
      height: 56px;
    }
  }
}

.modalContent {
  position: relative;
  margin-top: -16px;
  padding-top: 60px;

  @include tablet(min) {
    margin-top: -95px;
  }

  &ImageZoomer {
    margin: 0 auto;
  }
}

.videoItemCentered {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  display: block;
  transform: translate(-50%, -50%);
}

.videoItemIcon {
  opacity: 0.8;
  color: $color-olive-750;

  &:hover,
  &:focus {
    opacity: 0.9;
  }

  &:active {
    opacity: 1;
  }
}

.imageZoomer {
  div:hover {
    cursor: pointer !important;
  }

  &Zoomed {
    div:hover {
      cursor: zoom-in !important;
    }
  }
}

.vimeoVideo {
  width: 100%;
}
</style>
