<template>
  <aw-catalog-details-page v-if="isCatalogReady && catalog" />
</template>

<script setup>
  import { useRoute, useNuxtApp, useAsyncData, createError } from 'nuxt/app';
  import { useBreadcrumbStore } from '~~/common/stores/breadcrumb.js';
  import { usePromotionStore } from '~~/shop/stores/promotion.js';
  import { useSeoStore } from '~~/common/stores/seo.js';
  import { updateSeoHeadReactive } from '~~/common/mixins/seoMixin';
  import AwCatalogDetailsPage from '~~/shop/components/Page/Catalog/AwCatalogDetailsPage';

  defineOptions({
    name: 'CatalogPage',
  });
  // eslint-disable-next-line no-undef
  definePageMeta({
    layout: 'full-width',
  });
  const {
    $awt,
    $api,
    $logger,
  } = useNuxtApp();
  const route = useRoute();
  updateSeoHeadReactive();
  const promotionStore = usePromotionStore();
  const catalog = computed(() => promotionStore.catalog);
  const { data: isCatalogReady, error: asyncDataError } = await useAsyncData(
    async () => {
      try {
        const dynamicUrlResource = await $api.$get('/url-resource', {
          params: {
            slug: route.params.slug || '404',
          },
        });

        const { resourceId, resourceType } = (dynamicUrlResource || {});
        if (resourceType === 'catalog' && resourceId) {
          const breadcrumbStore = useBreadcrumbStore();
          const catalogRequestError = await promotionStore.fetchCatalog(resourceId);
          if (catalogRequestError) {
            throw createError({
              statusCode: catalogRequestError?.response?.status || 500,
              fatal: true,
              cause: catalogRequestError,
            });
          }
          {
            const { setPageTitlePrefix } = useSeoStore();

            setPageTitlePrefix({
              pageTitle: catalog.value.title,
            });
          }

          breadcrumbStore.setBreadcrumbs([
            {
              token: $awt('aw.common.category.home'),
              to: '/',
              disabled: false,
            },
            {
              token: $awt('aw.cms.catalog.list.breadcrumb'),
              to: '/catalog',
              disabled: false,
            },
            {
              token: catalog.value.title,
              to: '',
              disabled: true,
            },
          ]);
        } else {
          throw createError({
            statusCode: 404,
            fatal: true,
          });
        }
        return true;

      } catch (err) {
        $logger.error(err);
        if (err?.statusCode === 404) {
          throw err;
        }
        return false;
      }
    },
    {
      default: () => false,
    },
  );
  watchEffect(() => {
    if (asyncDataError.value?.statusCode === 404) {
      throw asyncDataError.value;
    }
  });
</script>
