<template>
  <div aria-atomic="true">
    <span class="awSrOnly" v-text="$awt('aw.common.components.rating.label', { rating_avg: modelValue })" />
    <div :class="$style.rating">
      <div
        v-for="star in stars"
        :key="`${star.type}-${star.value}`"
        aria-hidden="true"
        @click="emitChange(star.value)"
      >
        <aw-star-version2 :class="[$style.icon, {[$style.interactive]: interactive}]" :size="20" :percent="star.value" />
      </div>
    </div>
  </div>
</template>

<script>
  import AwStarVersion2 from '~~/common/components/Common/AwStarVersion2.vue';

  const PARTIAL_STAR_DIVIDED_BY = 0.25;
  const PARTIAL_STAR_MULTIPLIER = 25;

  export default {
    name: 'AwRatingVersion2',
    components: { AwStarVersion2 },
    props: {
      modelValue: {
        type: Number,
        default: 0,
      },
      interactive: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['update:modelValue'],
    computed: {
      stars () {
        const fullStars = Math.floor(this.modelValue);
        const partialRemaining = this.modelValue - fullStars;
        const partialStars = Math.ceil(this.modelValue - fullStars);
        const emptyStars = 5 - fullStars - partialStars;

        const fullArray = Array(fullStars).fill({ type: 'full', value: 100 });
        const partialArray = Array(partialStars).fill({ type: 'partial', value: Math.floor(partialRemaining / PARTIAL_STAR_DIVIDED_BY) * PARTIAL_STAR_MULTIPLIER });
        const emptyArray = Array(emptyStars).fill({ type: 'empty', value: 0 });
        return [...fullArray, ...partialArray, ...emptyArray];
      },
    },
    methods: {
      emitChange (index) {
        this.$emit('update:modelValue', index);
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.rating {
  display: grid;
  grid-template-columns: repeat(5, 24px);
  align-items: center;
  justify-content: center;

  .icon {
    stroke: none;

    &.interactive {
      cursor: pointer;
    }
  }
}
</style>
