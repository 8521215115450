<template>
  <div :class="$style.productDetail">
    <div :class="$style.productDetailTitleGrid">
      <div :class="$style.productDetailTitleContainer">
        <div :class="$style.productDetailTitle">
          <lv-heading
            :ref="Navigation[ID_PRODUCT_TOP]"
            tag="h1"
            level="2"
            version2
          >
            {{ productName }}
          </lv-heading>
        </div>
        <aw-favourite-version2
          v-if="isShop"
          v-bind="favData"
          :class="$style.favouriteItem"
          :default-list="onPreview ? listTypeEnum.OKAUCHAN : listTypeEnum.FAVOURITE"
          :need-login-message-in-toast-notification="onPreview"
        />
      </div>
      <div v-if="isShop && visibleReviewBlock" :class="$style.ratings">
        <lv-button v-bind="ratingWrapperAttrs" :class="$style.ratingsButton">
          <aw-rating-version2 :model-value="reviewAverageValue" />
          <span :class="$style.ratingsSum">
            {{ $awt('aw.common.product.detail.depend_on_ratings', { rating_sum: reviewSumCountValue }) }}
          </span>
        </lv-button>
      </div>
    </div>
    <slot name="subDetails" />
    <div v-if="hasCompare">
      <lv-button
        styling="small-secondary-dark"
        :has-icon="true"
        :class="$style.compareButton"
        @click="doCompare"
      >
        <span :class="$style.buttonText" v-text="$awt('aw.common.product_card.compare')" />
        <lv-icon :size="16" name="switcher-left-right-16" :class="$style.compareButtonIcon" />
      </lv-button>
    </div>
    <div v-if="displayedFlags.length" :class="$style.flagsRow">
      <aw-product-flag-items
        :product-data="{}"
        :flag-arr="displayedFlags"
        :is-small="screenRange['mobile-max']"
        flag-bg="white"
        :class="$style.flagsRowContent"
      />
    </div>

    <!-- region priceDisplay -->
    <div :class="$style.priceInfo">
      <!-- region originalPrice if discounted -->
      <div v-if="onSale" :class="$style.priceOld">
        <span class="awSrOnlyAbs" v-text="$awt('aw.common.product.detail.accessibility.regular_price')" />
        <del :class="$style.priceOldLineThrough">{{ priceOriginalGross }}</del>
        <span class="awSrOnlyAbs" v-text="$awt('aw.common.product.detail.accessibility.regular_unit_price')" />
        <span>{{ priceUnitOriginalGross }}</span>
      </div>
      <!-- endregion -->
      <!-- region primaryPrice -->
      <div
        :class="[
          $style.price,
          {
            [$style.priceTypeDiscounted]: productPriceType.priceType === PT_PRICE_DISCOUNT && productPriceType.isApplicable,
            [$style.priceTypeLoyalty]: productPriceType.priceType === PT_PRICE_LOYALTY && productPriceType.isApplicable,
          },
        ]"
      >
        <div v-if="primaryPriceText" :class="$style.pricePrimaryText">
          {{ primaryPriceText }}
        </div>
        <span class="awSrOnlyAbs" v-text="$awt('aw.common.product.detail.accessibility.price')" />
        <span :class="$style.pricePrimaryValue">{{ priceGross().text }}</span>
        <span v-if="rollPrice" :class="[$style.unitPrice, $style.priceRoll]">{{
          $awt(`aw.common.product.detail.price.${rollType === 'redemption' ? 'redemption_information' : 'roll_information'}`, { rollPrice })
        }}</span>
        <span class="awSrOnlyAbs" v-text="$awt('aw.common.product.detail.accessibility.unit_price')" />
        <div :class="$style.unitPrice" v-html="`(${priceUnitGross})`" />
      </div>
      <!-- endregion -->
      <!-- region notAppliedPrice -->
      <div
        v-if="!productPriceType.isApplicable && [PT_PRICE_LOYALTY].includes(productPriceType.priceType)"
        :class="$style.promoGrid"
      >
        <button
          type="button"
          :title="offerModalData.title"
          :aria-label="offerModalData.title"
          :class="[
            'buttonReset',
            $style.promoIcon,
            {
              [$style[`promoIcon--priceLoyalty`]]: productPriceType.priceType === PT_PRICE_LOYALTY,
            },
          ]"
          @click="openOfferModal"
        >
          <lv-icon :name="notAppliedPromoIcon" :size="16" />
        </button>
        <div :class="$style.promoText">
          <div :class="$style.promoName" v-text="offerModalData.shortName" />
          <div v-if="notAppliedPromoPriceStr" :class="$style.promoPrice" v-html="notAppliedPromoPriceStr" />
        </div>
      </div>
      <!-- endregion notAppliedPrice -->
    </div>
    <!-- endregion -->
    <div :class="$style.shipmentContainer">
      <template v-if="price.discountValidFrom && price.discountValidTo">
        <span :class="$style.productAdditionalDetailTitle">
          {{ $awt('aw.common.product.detail.discounted_period.title') }}
        </span>
        <span :class="$style.productAdditionalDetailValue">
          {{ formattedDiscountedPeriod }}
        </span>
      </template>
      <template v-if="isShop && isInVirtualStock && product.shipmentDays">
        <span :class="$style.productAdditionalDetailTitle">
          {{ $awt('aw.common.shipment_days.delivery_time.title') }}
        </span>
        <span :class="$style.productAdditionalDetailValue">
          {{ $awt('aw.common.shipment_days.delivery_time.in_x_days', { shipmentDays: product.shipmentDays }) }}
        </span>
      </template>
      <!-- Ideiglenesen deaktiválva a AUCHSUP-2440 jegy okán TODO: újraaktiválni később -->
      <template v-if="isShop && showDeliveryPriceBlock">
        <span :class="$style.productAdditionalDetailTitle">
          {{ $awt('aw.common.product.detail.shipping_free_box.text') }}
        </span>
        <span :class="[$style.productAdditionalDetailValue]">
          <template v-if="!deliveryMethod">
            <lv-button
              shrink
              styling="link-primary-version2"
              :class="$style.productAdditionalDetailValueButton"
              @click="openDeliveryMethodModal"
            >
              {{ $awt('aw.common.product.detail.set_postcode') }}
            </lv-button>
          </template>
          <template v-else>
            {{ $awt('aw.common.product.detail.delivery_price.from', { price: getDeliveryPrice }) }}
          </template>
        </span>
      </template>
      <template v-if="isShop && product.extraWeightPrice">
        <span :class="$style.productAdditionalDetailTitle">
          {{ $awt('aw.common.product.detail.extra_weight_price') }}
        </span>
        <span :class="$style.productAdditionalDetailValue">
          {{ extraWeightPrice() }}
        </span>
      </template>
    </div>
    <lv-tooltip
      v-if="isShop"
      :options="{placement: 'top'}"
      :class="$style.onlinePriceWarning"
      trigger="hover"
      aria-labelledby="only_online_order_box"
      is-false-tooltip
    >
      <template #tooltip>
        <span v-html="$awt('aw.common.product.detail.only_online_order_box.popup_text')" />
      </template>
      <template #reference>
        <span id="only_online_order_box" :class="$style.onlinePriceWarningTitle">
          {{ $awt('aw.common.product.detail.only_online_order_box.text') }}
        </span>
      </template>
    </lv-tooltip>
    <div ref="cartElement" />

    <div :class="$style.addToBasket">
      <div v-if="portalTargetStock" :id="portalTargetStock" />
      <aw-add-to-basket-version2
        :class="$style.addToBasketComponent"
        :portal-target-stock="portalTargetStock"
        :is-stock-change-allowed="true"
        :is-status-visible="isShop || isEcomUser"
        :is-unit-selector-visible="true"
        :is-cart-button-visible="true"
        :is-button-icon-visible="false"
        :is-button-text-visible="true"
        :is-unit-selector-small="false"
        :is-quantity-selector-small="false"
        :is-quantity-selector-double-sided="false"
        quantity-selector-direction="vertical"
        inner-class-stock=""
        inner-class-unit=""
        :inner-class-quantity="$style.addToBasketComponentQuantity"
        :inner-class-cart-btn="$style.addToBasketComponentCartButton"
        :inner-class-status="$style.statusTarget"
        :is-basket-page="false"
        :product-data="product"
        view-mode="details"
        dropdown-side="bottom"
        has-button-text
        :is-mobile-design="false"
        @added-to-cart="setAddedToCBasketStatus"
      />
    </div>

    <Teleport v-if="floatingCart" to="#awTeleportTargetSubHeader">
      <aw-basket-item-super-mini-horizontal
        :product-data="product"
        @added-to-cart="setAddedToCBasketStatus"
        @close="showMiniBasketItem = false"
      />
    </Teleport>

    <aw-product-old-product-modal
      :modal-type="modalType"
      :product-name="productName"
      @return-used-item="setReturnUsedItem"
    />
  </div>
</template>

<script>
  import { defineAsyncComponent, nextTick } from 'vue';
  import { mapState } from 'pinia';
  import { LvIcon, LvTooltip, LvButton, LvHeading } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { M_PROD_OLD } from '~~/common/config/Modal.js';
  import { uuid4 } from '~~/common/utils';
  import productDataMixin from '~~/common/mixins/productDataMixin';
  import pageInstanceMixin from '~~/common/mixins/pageInstanceMixin';

  import { useModalStore } from '~~/common/stores/modal';
  import { useOrderStore } from '~~/common/stores/order';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { useProductStore } from '~~/common/stores/product';
  import { useDeliveryStore } from '~~/shop/stores/delivery';
  import { useStockInfoStore } from '~~/dealer/stores/stockInfo';
  import { Navigation, focusRef, ID_PRODUCT_TOP, ID_MAIN } from '~~/common/config/Navigation.js';
  import { useCategoryStore } from '~~/common/stores/category.js';
  import { useUserStore } from '~~/common/stores/user.js';
  import * as priceTypes from '~~/common/config/priceTypes.js';
  import { PT_PRICE_DISCOUNT, PT_PRICE_LOYALTY } from '~~/common/config/priceTypes.js';
  import listTypeEnum from '~~/shop/components/Page/Profile/SaveBasket/enum/listTypeEnum';

  const offerDetailsModalContent = {
    [PT_PRICE_LOYALTY]: 'loyalty_card_offer',
  };

  export default {
    name: 'AwProductDetail',
    components: {
      AwAddToBasketVersion2: defineAsyncComponent(() => import('~~/common/components/Page/Common/BasketControl/AwAddToBasketVersion2.vue')),
      AwBasketItemSuperMiniHorizontal: defineAsyncComponent(() => import('~~/common/components/Common/AwBasketItemSuperMiniHorizontal')),
      AwProductOldProductModal: defineAsyncComponent(() => import('~~/common/components/Page/Product/AwProductOldProductModal')),
      AwProductFlagItems: defineAsyncComponent(() => import('~~/common/components/Page/Product/AwProductFlagItems.vue')),
      AwRatingVersion2: defineAsyncComponent(() => import('~~/common/components/Common/AwRatingVersion2.vue')),
      AwFavouriteVersion2: defineAsyncComponent(() => import('~~/common/components/Common/AwFavouriteVersion2.vue')),
      LvIcon,
      LvTooltip,
      LvButton,
      LvHeading,
    },
    mixins: [
      pageInstanceMixin,
      productDataMixin,
    ],
    props: {
      reviewLink: {
        type: String,
        default: '',
      },
      onPreview: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        modalType: M_PROD_OLD + uuid4(),
        isCartInViewport: true,
        isViewportUnderCart: true,
        cetelemScriptTag: null,
        Navigation,
        ID_PRODUCT_TOP,
        ID_MAIN,
        focusRef,
        portalTargetStock: '',
        ...priceTypes,
        showMiniBasketItem: true,
        listTypeEnum,
      };
    },
    computed: {
      ...mapState(useUserStore, {
        isEcomUser: state => state.isEcomUser,
      }),
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
      ...mapState(useProductStore, {
        product: state => state.data,
      }),
      ...mapState(useOrderStore, {
        order: state => state.order,
        returnUsedItem: state => state.returnUsedItem,
      }),
      ...mapState(useDeliveryStore, {
        deliveryMethod: state => state.setup,
      }),
      ...mapState(useCategoryStore, {
        hasCompare: state => state.category?.comparable,
      }),
      floatingCart () {
        return !this.isCartInViewport && this.isViewportUnderCart && this.showMiniBasketItem;
      },
      uuids () {
        return (this.order.splits
          .map((split) => {
            return (split.packages
              .map((splitPackage) => {
                return (splitPackage.items
                  .map((item) => {
                    if (item.objectId === this.variantId.toString() && item.usedItem.canReturn && this.returnUsedItem[this.variantId] == null) {
                      return item.uuid;
                    }
                    return undefined;
                  })
                );
              })
              .flat()
            );
          })
          .flat()
          .filter(item => item)
        );
      },
      showDeliveryPriceBlock () {
        return this.deliveryPrice?.cartType !== 'FOOD' && this.deliveryPrice?.fee;
      },
      getDeliveryPrice () {
        if (this.deliveryPrice?.fee) {
          const rawPrice = this.deliveryPrice?.fee;
          return rawPrice ? this.$price({ gross: rawPrice.grossDiscounted }, rawPrice.currency) : '';
        } else {
          return '';
        }
      },
      ratingWrapperAttrs () {
        const attrs = {
          shrink: true,
          styling: 'link-simple',
        };
        if (this.reviewLink) {
          attrs.href = `#${this.reviewLink}`;
        } else {
          attrs.tag = 'div';
          attrs.class = this.$style.ratingNotClickable;
        }
        return attrs;
      },
      favData () {
        const variant = this.getVariant;
        return {
          variantId: this.variantId,
          shoppingListData: variant.shoppingListsContain,
          selectedValue: variant.unit,
          count: variant.cartInfo.quantityStepSize,
          productName: this.productName,
          productData: this.product,
          productId: this.productId,
          productImage: this.images,
        };
      },
      formattedDiscountedPeriod () {
        const hasDiscountDate = this.price.discountValidFrom && this.price.discountValidTo;
        return hasDiscountDate ? `${this.priceDiscountedPeriod().from} - ${this.priceDiscountedPeriod().to}` : null;
      },
      primaryPriceText () {
        if (this.productPriceType.priceType === PT_PRICE_DISCOUNT && this.productPriceType.isApplicable) {
          if (this.discountPercentage >= 10) {
            return this.$awt('aw.common.product.detail.price.discounted_by', { discountPercentage: this.discountPercentage });
          } else {
            return this.$awt('aw.common.product.detail.price.discounted');
          }
        }
        if (this.productPriceType.priceType === PT_PRICE_LOYALTY && this.productPriceType.isApplicable) {
          return this.$awt('aw.common.product.detail.price.has_loyalty');
        }
        return null;
      },
      notAppliedPromoIcon () {
        return 'cheque-16';
      },
      offerModalData () {
        const content = offerDetailsModalContent[this.productPriceType.priceType];
        const shortName = {
          [PT_PRICE_LOYALTY]: 'aw.common.product.detail.price.discount_with_loyalty',
        }[this.productPriceType.priceType];
        return {
          content,
          title: this.$awt(`aw.static_content_page.${content}`),
          shortName: this.$awt(shortName),
        };
      },
    },
    mounted () {
      const uuid = uuid4();
      focusRef(this, {
        focusElementRefId: Navigation[ID_PRODUCT_TOP],
        scrollElementRef: document.getElementById(Navigation[ID_MAIN]),
      });
      if (!this.isShop) {
        this.portalTargetStock = 'stock-' + uuid;
        const stockInfoStore = useStockInfoStore();
        stockInfoStore.fetchStock({
          productIds: [this.productId],
          details: false,
        });
      }
      const productStore = useProductStore();
      this.awCartHeightObserver = new IntersectionObserver((entries) => {
        const [{
          isIntersecting,
          boundingClientRect,
        }] = entries.slice(-1);
        this.isCartInViewport = isIntersecting;
        this.isViewportUnderCart = boundingClientRect.top < 0;
      }, {});
      nextTick(() => {
        this.awCartHeightObserver.observe(this.$refs.cartElement);
      });
      if (!this.isShop) {
        const stockInfoStore = useStockInfoStore();
        stockInfoStore.fetchStock({
          productIds: [this.productId],
          details: true,
        });
      }
      productStore.fetchDeliveryPrice({
        id: this.productId,
        variantId: this.variantId,
      });
      focusRef(this, {
        focusElementRefId: Navigation[ID_PRODUCT_TOP],
        scrollElementRef: document.getElementById(Navigation[ID_MAIN]),
      });
    },
    unmounted () {
      this.awCartHeightObserver?.disconnect();
      delete this.awCartHeightObserver;
    },
    methods: {
      openDeliveryMethodModal () {
        const modalStore = useModalStore();
        modalStore.openDeliveryMethodModal();
      },
      async setReturnUsedItem (returnUsed) {
        const modalStore = useModalStore();
        const orderStore = useOrderStore();
        if (returnUsed) {
          for (const uuid of this.uuids) {
            await orderStore.updateCartItemReturnUsed({
              uuid,
              returnUsed,
              fromBasket: false,
            });
          }
          orderStore.setReturnUsedItem({ [this.variantId]: true });
        } else {
          orderStore.setReturnUsedItem({ [this.variantId]: false });
        }
        modalStore.hideProdOld({ modalType: this.modalType });
      },
      setAddedToCBasketStatus (success) {
        const modalStore = useModalStore();
        if (!this.isShop) {
          const stockInfoStore = useStockInfoStore();
          stockInfoStore.fetchStock({
            productIds: [this.productId],
            details: true,
          });
        }
        if (success && this.product?.usedItem?.canReturn) {
          this.order.splits.forEach((split) => {
            split.packages.forEach((splitPackage) => {
              splitPackage.items.forEach((item) => {
                if (item.objectId === this.variantId.toString() && item.usedItem.canReturn && this.returnUsedItem[this.variantId] == null) {
                  if (this.isShop || splitPackage.discriminator.deliveryMode !== 'store') {
                    modalStore.showProdOld({ modalType: this.modalType });
                  } else {
                    modalStore.hideProdOld({ modalType: this.modalType });
                  }
                }
              });
            });
          });
        }
      },
      openOfferModal () {
        const modalStore = useModalStore();
        modalStore.openStaticContentModal({
          content: this.offerModalData.content,
          title: this.offerModalData.title,
        });
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
%lineClamp {
  line-height: var(--lineHeight);
  display: -webkit-box;
  overflow: hidden;
  min-height: calc(var(--lineNumber) * var(--lineHeight));
  text-overflow: ellipsis;
  -webkit-line-clamp: var(--lineNumber);
  -webkit-box-orient: vertical;
}

.productDetail {
  padding-top: 20px;

  @include tablet(min) {
    padding-top: 0;
  }

  &TitleContainer {
    display: flex;
    gap: 10px;
    grid-area: productTitle;
  }

  &Title {
    flex-grow: 1;
    color: $color-text-primary;
  }

  &TitleGrid {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-areas: "ratings" "productTitle";
  }
}

.itemVolumeInfo {
  font-size: 10px
}

.ratings {
  margin-bottom: 20px;
  border: 0;
  grid-area: ratings;

  &Button {
    display: flex;
    align-items: center;
  }

  &Sum {
    font-size: 14px;
    font-weight: $font-weight-normal-v2;
    line-height: 20px;
    display: block;
    padding-left: 8px;
  }
}

.priceInfo {
  margin-top: 20px;
}

.flagsRow {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 20px 0;
  border-top: 1px dashed $color-dash-separator;
  border-bottom: 1px dashed $color-dash-separator;
  gap: 8px 16px;

  &Content {
    display: contents;
  }
}

.price {
  display: inline-block;
  word-break: break-word;
  border-radius: 16px;

  &Type {
    &Discounted {
      padding: 12px;
      color: $color-strawberry;
      background-color: $color-strawberry-450--12;
    }

    &Loyalty {
      padding: 12px;
      color: $color-text-sage;
      background-color: $color-sage-450--12;
    }
  }

  &Roll {
    margin-left: 1ch;
  }

  &Primary {
    &Text {
      font-size: 12px;
      line-height: 16px;

      @include tablet(min) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &Value {
      font-size: 26px;
      font-weight: $font-weight-bold-v2;
      line-height: 32px;

      @include tablet(min) {
        font-size: 40px;
        font-weight: $font-weight-bold-v2;
        line-height: 48px;
      }
    }
  }

  &Old {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    gap: 8px;
    margin-bottom: 8px;

    &LineThrough {
      font-weight: $font-weight-bold-v2;
      text-decoration: line-through;
    }
  }
}

.unitPrice {
  font-family: $secondary-font;
  font-size: 12px;
  line-height: 16px;
  margin-top: 3px;

  @include tablet(min) {
    font-size: 14px;
    font-weight: $font-weight-normal-v2;
    line-height: 20px;
  }
}

.statusTarget {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  padding: 16px 8px;
  text-align: center;
  white-space: nowrap;
  color: $color-text-tertiary;
  border-radius: 12px;
  background: change-color($color-background-1, $alpha: 0.8);
}

.onlinePriceWarning {
  margin-top: 12px;

  &Title {
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: $font-weight-normal-v2;
    font-style: italic;
    line-height: 20px;
    text-align: left;
    color: $color-text-secondary;
  }
}

.addToBasket {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px dashed $color-dash-separator;
  gap: 15px;

  &Component {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px;
    border-radius: 16px;
    background-color: $color-background-3;
    gap: 8px;

    &Quantity {
      flex-basis: 80px;
    }

    &CartButton {
      flex-grow: 1;
    }

    &Stock {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: center;
      padding: 16px 8px;
      text-align: center;
      white-space: nowrap;
      color: $color-text-tertiary;
      border-radius: 12px;
      background: change-color($color-background-1, $alpha: 0.8);
    }
  }
}

.ratingNotClickable {
  cursor: default;
}

.favouriteItem {
  margin-top: 4px;
}

.compareButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin-top: 20px;
  gap: 8px;

  &Icon {
    height: 20px;
    vertical-align: unset;
  }
}

.shipmentContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 8px;
  align-items: flex-end;

  &:not(:empty) {
    margin-top: 20px;
  }
}

.productAdditionalDetail {
  &Title {
    font-size: 14px;
    font-weight: $font-weight-normal-v2;
    line-height: 20px;
    color: $color-text-secondary;
  }

  &Value {
    font-size: 14px;
    font-weight: $font-weight-bold-v2;
    line-height: 20px;
    color: $color-text-primary;

    &Button {
      font-size: 14px;
      vertical-align: unset;
      text-decoration: underline;
    }
  }
}

.promoGrid {
  display: grid;
  margin-top: 20px;
  grid-template-columns: auto 1fr;
  column-gap: 8px;
}

.promoText {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.promoName {
  @extend %lineClamp;
  --lineHeight: 16px;
  --lineNumber: 1;
  font-size: 12px;
  font-weight: $font-weight-medium-v2;
}

.promoPrice {
  @extend %lineClamp;
  --lineHeight: 16px;
  --lineNumber: 1;
  font-size: 12px;
}

.promoIcon {
  &--priceLoyalty {
    --awProductCardOfferTypeIconColor: #{$color-sage-450};
    --awProductCardOfferTypeIconBg: #{$color-sage-450--12};
  }
  line-height: 0;
  align-self: center;
  padding: 6px;
  cursor: help;
  color: var(--awProductCardOfferTypeIconColor, $color-black);
  border-radius: 8px;
  background: var(--awProductCardOfferTypeIconBg, $color-white);

  grid-row: 1/-1;
  justify-self: center;
}
</style>
