<template>
  <aw-spring :desktop-size="10" :class="$style.catalogPageWrapper">
    <aw-breadcrumb-version2 />

    <div :class="$style.catalog">
      <client-only>
        <div>
          <lv-heading
            tag="h1"
            level="1"
            version2
            align="center"
          >
            {{ catalog.title }}
          </lv-heading>

          <p
            :class="$style.catalogAvailabilityText"
            :aria-label="$awt('aw.cms.catalog.details.date_availability_label', {available_date_range: catalogAvailabilityText})"
          >
            {{ catalogAvailabilityText }}
          </p>

          <p :class="$style.catalogStores">
            {{ $awt('aw.cms.catalog.details.stores', { store_names: catalog.stores.join(', ') }) }}
          </p>
        </div>

        <iframe
          id="catalogFlipbook"
          :class="$style.catalogFlipbook"
          data-ipaper
          :src="catalog.flipbookUrl"
          width="100%"
          :height="calculatedFlipbookHeight"
        />
      </client-only>
      <div :class="$style.buttonWrapper">
        <lv-button
          styling="secondary-dark"
          :href="catalog.pdf"
          download
          target="_blank"
          :class="$style.button"
          stretch
        >
          {{ $awt('aw.cms.catalog.details.download') }}
        </lv-button>
      </div>

      <aw-modal
        :opened="showModal"
        size="lg"
        :modal-title="$awt('aw.cms.catalog.details.preview_modal.title')"
        :modal-content-classes="[$style.modalContent]"
        @close-modal="closeModal"
      >
        <template #content>
          <span class="awSrOnlyAbs" tabindex="1" v-text="$awt('aw.cms.catalog.details.preview_modal.title') + ':' + currentPreviewItem.selectedVariant.name" />
          <aw-separator margin-top="none" />
          <product-preview />
          <aw-separator />
          <lv-button
            :href="getProductUrl(currentPreviewItem.selectedVariant)"
            target="_blank"
            type="button"
            styling="primary-dark"
            :has-icon="true"
            :stretch="screenRange['mobile-max']"
          >
            {{ $awt('aw.cms.catalog.details.preview_modal.navigate_button') }}
          </lv-button>
        </template>
      </aw-modal>
    </div>
  </aw-spring>
</template>

<script setup>
  import { useNuxtApp } from 'nuxt/app';
  import { computed, useCssModule, nextTick } from 'vue';
  import { LvButton, LvHeading } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwBreadcrumbVersion2 from '~~/common/components/Common/AwBreadcrumbVersion2.vue';
  import AwSpring from '~~/common/components/AwSpring.vue';
  import AwModal from '~~/common/components/Common/AwModal.vue';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { usePromotionStore } from '~~/shop/stores/promotion';
  import ProductPreview from '~~/shop/components/Page/Product/ProductPreview.vue';
  import { useProductStore } from '~~/common/stores/product';
  import { useOrderStore } from '~~/common/stores/order';
  import { useDeliveryStore } from '~~/shop/stores/delivery';
  import { useModalStore } from '~~/common/stores/modal';
  import { useNotificationStore } from '~~/common/stores/notification';
  import { useCategoryStore } from '~~/common/stores/category';
  import { PUSH_ADD_TO_CART } from '~~/common/plugins/aw-analytics';
  import AwSeparator from '~~/common/components/Common/AwSeparator.vue';
  import { getProductUrl } from '~~/common/mixins/urlMixin';

  const {
    $date,
    $awt,
    $awAnalytics,
    $awMergedConfig: { iPaperApiUrl },
  } = useNuxtApp();
  const $style = useCssModule();

  const orderStore = useOrderStore();
  const deliveryStore = useDeliveryStore();
  const modalStore = useModalStore();
  const notificationStore = useNotificationStore();
  const categoryStore = useCategoryStore();

  const currentPreviewItem = ref(null);
  const showModal = ref(false);

  const screenRange = computed(() => useUserInterfaceStore().mediaQueries);
  const catalog = computed(() => usePromotionStore().catalog);

  const isDomReady = ref(false);

  const catalogAvailabilityText = computed(() => {
    const dateOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const extraOptions = {
      removeSpaces: true,
    };

    return `${$date(catalog.value.availabilityFromDate, dateOptions, extraOptions)} - ${$date(catalog.value.availabilityToDate, dateOptions, extraOptions)}`;
  });

  const calculatedFlipbookHeight = computed(() => {
    if (isDomReady.value) {
      const springContainer = document.querySelectorAll(`.${$style.catalogPageWrapper} [data-spring-container]`);
      const width = springContainer[0].offsetWidth;
      const a4PageRatio = 1.4142;

      return screenRange.value['tablet-min'] ? (width / 2) * a4PageRatio : width * a4PageRatio;
    } else {
      return 0;
    }
  });

  onMounted(() => {
    isDomReady.value = document.querySelectorAll(`.${$style.catalogPageWrapper} [data-spring-container]`).length > 0;
  });

  watch(isDomReady, (newVal) => {
    if (newVal) {
      const flipbookScriptId = 'flipbook_script';
      let flipbookScript = document.getElementById(flipbookScriptId);

      if (!flipbookScript) {
        flipbookScript = document.createElement('script');
        flipbookScript.src = iPaperApiUrl;
        flipbookScript.async = true;
        document.head.appendChild(flipbookScript);
      }

      nextTick(() => {
        if (window.iPaperJsApi) {
          initOnPageElementClick();
        } else {
          flipbookScript.addEventListener('load', () => {
            initOnPageElementClick();
          });
        }
      });
    }
  });

  function initOnPageElementClick () {
    // eslint-disable-next-line no-undef
    const iPaperInstance = iPaperJsApi(document.getElementById('catalogFlipbook'), 3);
    iPaperInstance.basket.onProductAdd((event) => { handlePageElementClick(event); });
  }

  async function handlePageElementClick (data) {
    const auchanCode = data?.productId?.split('-')[0];
    const fetchedProduct = await fetchProduct(auchanCode);

    if (fetchedProduct) {
      if (data?.description === 'Modal') {
        openPreviewModal(fetchedProduct);
      } else if (data?.description === 'Basket') {
        await addToBasket(fetchedProduct);
      }
    }
  }

  function closeModal () {
    currentPreviewItem.value = null;
    showModal.value = false;
  }

  function openPreviewModal (data) {
    currentPreviewItem.value = data;
    showModal.value = true;
  }

  async function fetchProduct (productId) {
    return await useProductStore().fetchMainProduct({
      identifierType: 'sku',
      id: productId,
      specialErrorMessage: { response: { data: { message: $awt('aw.cms.catalog.details.error.product_not_found') } } },
    });
  }

  async function addToBasket (product) {
    const cartData = {
      id: 'current',
      objectType: 'variant',
      data: {
        objectId: product?.selectedVariant?.id + '',
        quantity: {
          quantity: product?.selectedVariant?.cartInfo?.quantityStepSize ?? 1,
          type: ['db', 'piece', 'szt.'].includes(product?.selectedVariant?.unit) ? 'piece' : 'weight',
        },
        stockType: 'store',
      },
    };

    if (!deliveryStore.setup) {
      modalStore.openDeliveryMethodModal({ cartData });
      return;
    }

    const result = await orderStore.addToCart(cartData);

    // All ok
    if (result) {
      const cartItem = orderStore.getCurrentFlattenedOrderItems.find(item => item.variant.id === product?.selectedVariant?.id);
      notificationStore.pushSuccess({
        manualClose: false,
        text: {
          title: $awt('aw.common.product_card.screen_reader_label.successfully_added_to_card', {
            product_name: product?.selectedVariant?.name,
            quantity: `${cartItem.quantity.quantity} ${product?.selectedVariant?.unit}`,
          }),
        },
      });

      $awAnalytics[PUSH_ADD_TO_CART]({
        productData: product,
        productList: categoryStore.category?.name,
        quantityData: {
          quantity: cartData.data.quantity.quantity,
          type: cartData.data.quantity.type,
        },
      });
    } else if (result == null) {
      // do nothing
    } else { // Error
      notificationStore.pushSuccess({
        manualClose: false,
        text: {
          title: $awt('aw.common.product_card.screen_reader_label.error', { product_name: product?.selectedVariant?.name }),
        },
      });
    }
  }

</script>

<style module lang="scss" rel="stylesheet/scss">
.catalog {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 16px;
  border-radius: 24px;
  background: white;

  @include tablet(min) {
    padding: 40px 24px;
    border-radius: 32px;
  }

  @include desktop-small(min) {
    padding: 40px;
  }


  &PageWrapper {
    [data-spring-container] {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr;

      @include tablet(min) {
        gap: 40px;
      }
    }
  }

  &AvailabilityText {
    margin: 20px 0;
  }

  &AvailabilityText, &Stores {
    text-align: center;
    color: $color-text-secondary;
  }
}

.catalogFlipbook {
  padding: 20px 0;
  border: unset;

  @include tablet(min) {
    padding: 40px 0;
  }
}

.modalContent {
  display: flex;
  align-items: center;
  flex-direction: column;
}

</style>

