<template>
  <div :class="$style.wrapper">
    <aw-spinner v-if="productLoading || isAdultContentHidden" />
    <div v-else-if="productId && !isAdultContentHidden" :class="$style.productPage">
      <div :class="$style.productDetailsGridRowDetails">
        <div :class="$style.productDetailsGridDetails">
          <div id="productDetails" :class="$style.productDetails">
            <aw-product-detail v-if="productId" :on-preview="true" />
            <div v-else :class="$style.preloadDetails">
              <aw-spinner />
            </div>
          </div>
        </div>
        <div :class="$style.productDetailsGridGallery">
          <aw-product-gallery-version2 :id-flix-minisite="ID_FLIX_MINISITE" :need-modal="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, defineAsyncComponent } from 'vue';
  import AwProductDetail from '~~/common/components/Page/Product/AwProductDetail';
  import AwProductGalleryVersion2 from '~~/common/components/Page/Product/AwProductGalleryVersion2';
  import { useProductDataMixin } from '~~/common/mixins/productDataMixin';

  import { useProductStore } from '~~/common/stores/product';
  import { useUserStore } from '~~/common/stores/user';
  const AwSpinner = defineAsyncComponent(() => import('~~/common/components/Common/AwSpinner.vue'));

  const ID_FLIX_MINISITE = 'flix-minisite';

  const productData = computed(() => {
    return useProductStore().data;
  });
  const {
    productId,
    productCategoryId,
  } = useProductDataMixin({ productData });

  const productLoading = computed(() => useProductStore().loading);
  const isAdultContentHidden = computed(() => {
    const productAdultsOnly = useProductStore().data?.adultsOnly;
    const ageConfirmed = useUserStore().data?.personalInfo?.ageConfirmed;
    return productAdultsOnly && !ageConfirmed;
  });

  onMounted(async () => {
    const productStore = useProductStore();

    await productStore.fetchProductCategory({
      categoryId: productCategoryId.value,
    });
  });
</script>

<style module lang="scss" rel="stylesheet/scss">
.wrapper {
  width: 100%;
}

.productPage {
  .productDataEraserImage {
    margin: 12px 0;
  }
}

.productDetailsGridRowDetails {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-areas: "gallery" "details";

  @include tablet(min) {
    grid-template-rows: unset;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "gallery details";
    gap: 0 24px;
  }

  @include desktop-small(min) {
    gap: 0 32px;
  }

  @include desktop-medium(min) {
    gap: 0 40px;
  }

  .productDetailsGridGallery {
    grid-area: gallery;
  }

  .productDetailsGridDetails {
    grid-area: details;
    padding: 0 16px;

    @include tablet(min) {
      padding: 0;
    }

    @include desktop-small(min) {
      padding-left: 32px;
    }

    @include desktop-medium(min) {
      padding: 0 40px;
    }
  }

  .productDetails {
    transition: 0.5s;

    .preloadDetails {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 500px;
      background: $color-white;
    }
  }
}
</style>
